@import "./_vars";

.member-details-image {
  height: 175px;
  width: 175px;
  margin-right: 2em;
  .image-selector {
    background-color: red;
    width: 2em;
  }
  position: relative;
}
.details-card {
  .section {
    margin-bottom: 2em;
  }
  .add-photo {
    position: absolute;
    bottom: 0.3em;
    right: 0.3em;
  }
  .status-select {
    flex: 1;
  }
  .date-picker {
    margin: 0 0 1.5em 0;
  }
  .phone-input {
    font-size: 0.7em;
    margin-bottom: 0.9em;
    font-family: $helvetica-neue;
    color: rgba(0, 0, 0, 0.54);
  }

  .phone-input-error {
    font-size: 0.7em;
    margin-bottom: 0.9em;
    font-family: $helvetica-neue;
    color: $error-red;
  }
  .react-phone-number-input {
    input {
      margin: 0 0 0 0;
      font-family: $helvetica-neue;
      color: $med-gray;
      border-color: #aaa6a6;
    }
  }
  .react-phone-number-input--focus {
    input {
      margin: 0 1.5em 0 0;
      font-family: $helvetica-neue;
      color: $med-gray;
      border-color: $primary;
    }
  }
  .member-input {
    margin: 0 0 0 0;
  }
  .member-input-error {
    margin: 0 1.5em 1.5em 0;

    input {
      border-bottom: 1px solid $error-red;
    }
  }
  .country-input {
    max-height: 50em;
    min-width: 9em;
    margin-bottom: -0.2em;
  }

  .state-select {
    max-width: 5em;
    margin-right: 1.5em;
    flex: 1;
    margin-top: 0.1em;
  }

  .button {
    button {
      margin-bottom: 2em;
      width: 17em;
    }
  }
}
