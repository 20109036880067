@import "./_vars";

@font-face {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Neue LT Std 45 Light"), url("../fonts/HelveticaNeueLTStd Lt.woff") format("woff");
}

html {
  font-size: 1em;
  font-family: $helvetica-neue, "Source Sans Pro", sans-serif;
  letter-spacing: 1px;
  scroll-behavior: smooth;
  background-color: $very-light-gray;
}
@media screen and (max-width: 1024px) {
  html {
    font-size: 0.9em;
  }
}
h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.75em;
  margin-bottom: 1.25em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  color: $dark-gray;
  // font-weight: 600;
  font-size: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: 700;
  color: $med-gray;
}

p {
  font-size: 1em;
}
a {
  text-decoration: none;
}
.input-group {
  display: flex;
  flex-direction: column;
}
input,
select {
  border: none;
  border-bottom: solid 2px $light-gray;
  line-height: 2em;
  font-size: 1.1em;
  color: $dark-gray;
}
textarea {
  font-family: $helvetica-neue, "Source Sans Pro", sans-serif;
}
input:active {
  border-bottom: $primary;
  color: $dark-gray;
}
input:focus {
  outline-width: 0;
  border-bottom: $primary solid 2px;
  color: $dark-gray;
}
.input-error {
  border-bottom: $error-red solid 2px;
  font-size: 1.1em;
  color: $error-red;
}
button {
  border-radius: 0.4em;
  padding: 1em;
  border-color: $primary;
  color: $primary;
  background-color: white;
}
.row {
  display: flex;
  flex-direction: row;
}
.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.column {
  display: flex;
  flex-direction: column;
}
.flex {
  display: flex;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-10 {
  flex: 10;
}
.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 2em;
}
.mt-3 {
  margin-top: 3em;
}
.mt-4 {
  margin-top: 4em;
}
.mt-5 {
  margin-top: 5em;
}
.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mb-3 {
  margin-bottom: 3em;
}
.mb-4 {
  margin-bottom: 4em;
}
.mb-5 {
  margin-bottom: 5em;
}
.ml-1 {
  margin-left: 1em;
}
.ml-2 {
  margin-left: 2em;
}
.ml-3 {
  margin-left: 3em;
}
.ml-4 {
  margin-left: 4em;
}
.ml-5 {
  margin-left: 5em;
}
.mr-05 {
  margin-right: 0.5em;
}
.mr-1 {
  margin-right: 1em;
}
.mr-2 {
  margin-right: 2em;
}
.mr-3 {
  margin-right: 3em;
}
.mr-4 {
  margin-right: 4em;
}
.mr-5 {
  margin-right: 5em;
}
.flex-100 {
  flex: 1;
  width: 100%;
}

th {
  z-index: 1 !important;
}

//fix for fixed header and anchor tags
.anchor {
  padding-top: 4em;
  margin-top: -4em;
}

//paper and table wrappers
.paper-base {
  padding: 1.25em;
  margin-bottom: 2.5em;
}
.paper-selected {
  border-style: solid;
  border-width: 1px;
  border-color: $link-blue;
}
.paper-table {
  margin-bottom: 2.5em;
}
.paper-header {
  font-size: 1em;
  font-family: $helvetica-neue;
  font-weight: 600;
  color: $med-gray;
}
.material-table-header {
  font-size: 0.8em;
  font-family: $helvetica-neue;
  font-weight: 600;
  color: $med-gray;
  margin-left: -1.2em;
}

//multi-selects w/ chips
.tagform-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

//content cards for events and members
.card-wrap-container {
  display: flex;
  flex-flow: row wrap;
  .wrapping-row-item {
    margin: 0 2.5em 2.5em 0;
    width: calc((100% / 3) - 2.5em);
    img {
      display: block;
      margin: 0 auto;
      width: 4.5em;
      height: 4.5em;
    }
    button {
      font-family: $helvetica-neue;
    }
  }
  .feature {
    width: calc((100% / 3) * 2);
  }
}

//2 col layout. Messages, News, Polls
.two-col-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }
  .col-one {
    flex: 1;
    padding-right: 2.5em;
    .search {
      margin: 1em auto;
      width: 95%;
    }
  }
  .col-two {
    flex: 2;
  }
}

//2 col card/paper layout for news & message items
.two-col-item-container {
  display: flex;
  flex-direction: row;
  min-height: 5em;
  max-height: 8em;
  .two-column {
    display: flex;
    flex-direction: column;
  }
  .profile-pic {
    width: 25%;
    align-items: center;
    justify-content: center;
  }
  .details {
    width: 75%;
    padding-top: 15px;
    padding-left: 1em;
    .title-date {
      display: flex;
      flex-direction: row;
      .title {
        text-align: left;
        font-size: 1.1em;
        font-weight: bold;
        color: $primary;
      }
      .date {
        text-align: right;
        color: $med-gray;
      }
    }
    .title-date li {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-bottom: 10px;
    }
    .content {
      color: $med-gray;
      overflow: hidden;
    }
    .content-messages {
      color: $med-gray;
    }
  }
}
