@import "../../styles/vars";
.footer-container {
  background: $dark-gray;
  color: white;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
