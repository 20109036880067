
.event-detail-subheader {
  .subheader-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 10em;

    .event-subheader-summary {
      display: flex;
      flex-direction: row;
      margin-left: 18em;
      img {
        height: 7.5em;
        margin: 0 2em 0 0;
      }
      .event-subheader-details {
        display: flex;
        flex-direction: column;
        padding: 0.5em 0;
        h2 {
          color: $very-light-gray;
        }
        h5 {
          color: $very-light-gray;
          font-weight: normal;
          margin-top: 0.75em;
        }
      }
    }
    .event-header-buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-right: 5em;
      button {
        margin: 1em 0 0 0;
      }
    }
  }
}