@import "../../styles/vars";

.subheader-container {
  border-bottom: solid 2px $primary;
  margin-bottom: 2.5em;
  display: inline-block;
  h1 {
    margin-bottom: 5px;
    border-bottom: solid 5px $primary;
    padding-bottom: 5px;
  }
}
.main-header {
  display: block;
  margin-top: -5px;
  margin-bottom: 0;
}
.secondary-header {
  border-bottom: none;
}
