@import "../../styles/vars";

.spinner-container {
  margin: 50px auto;
}

.no-events-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 150%;
  text-align: center;
  width: 100%;
  margin-top: 3em;
  height: 10em;
}
