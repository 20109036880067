.document-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.photo-loader {
  margin: auto;
  align-self: center;
}

// .document-modal {
//   z-index: 100;
// }
