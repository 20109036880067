@import "../../styles/vars";

.conversation-header {
  text-align: center;
  margin-bottom: 20px;
}
.message-items-bottom {
  float: left;
  clear: both;
}

.compose-message-container {
  clear: both;
  .message-text {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  .members-select-list {
    float: left;
  }
  .message-submit {
    float: right;
  }
  .message-warn {
    color: red;
  }
}

.member-selectlist-container {
  .input-label {
    padding: 0 1em;
  }
  .chips {
    display: flex;
    flex-wrap: wrap;
  }
  .chip {
    margin: 0.25em;
  }
  .select-all-btn-container {
    padding: 1em 0;
  }
}
