@import "./_vars";

.details-card {
  flex-direction: "row";
  .section {
    margin-bottom: 2em;
  }
  .divided-row {
    flex: 1;
  }
  .member-input {
    margin: 0 1.5em 1.5em 0;
  }
  .column {
    .button {
      button {
        width: 5em;
      }
    }
  }
}
