@import "../../styles/vars";
.event-details-container {
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 2em;
  
  h1 {
    margin: 2em 0 1em 1.5em;
    color: $med-gray;
    font-weight: 900;
  }
}