.member-container {
  .subheader-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 12em;
    .summary {
      display: flex;
      flex-direction: row;
      margin-left: 18em;
      img {
        width: 7.5em;
        margin: 0 2em 0 0;
      }
      .subheader-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5em 0;
        h2 {
          color: $very-light-gray;
        }
        h4 {
          color: $very-light-gray;
        }
      }
    }
    .header-buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: "center";
      margin-right: 5em;
      button {
        margin: 1em 0 0 0;
      }
      a {
        text-decoration: none;
        width: 100%;
      }
    }
  }
}
