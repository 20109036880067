@import "../../../styles/vars";

.upcoming-events-card {
  flex-direction: column;
  padding: 1.5em;
  background-color: #fff;

  .section {
    margin-bottom: 1em;
    border-bottom: 1px solid $very-light-gray;
    padding-bottom: 0.5em
  }

  .upcoming-events-grid {
    display: flex;
    flex-direction: column;
    height: 350px;
    overflow-y: auto;
    padding-right: 1em;
  }

  .upcoming-event-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid $light-gray;
  }

  .upcoming-event-date {
    min-width: 7em;
    margin-right: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .upcoming-event-month {
    margin-bottom: 5px;
  }

  .upcoming-event-location {
    display: flex;
    flex: 1;
    font-weight: bold;
  }
}
