@import "../../../styles/vars";

.create-member-container {
  display: flex;
  flex-direction: column;

  .input-row {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }
  }

  .form-button {
    margin: 0 2em 2em 0;
  }

  .status-select {
    flex: 0.5;
  }

  .input-field {
    display: flex;
    flex: 1;
    margin-right: 2em;
    margin-left: 2em;
    margin-bottom: 1em;
  }

  .cancel-button {
    margin-right: auto;
  }
}
