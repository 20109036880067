@import "../styles/_vars";
.signin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15em 0 0 0;
  img {
    width: 12.5em;
    margin: 0 0 2.5em;
  }
  h2 {
    color: $med-gray;
    margin: 0 0 1em;
  }
  .signin-form {
    width: 32em;
    padding: 2em;
    display: flex;
    flex-direction: column;
    .login-input {
      flex: 1;
      margin: 0 0 3em 0;
      input {
        width: 32em;
      }
    }
    .button {
      width: 8em;
      align-self: center;
    }
  }
}
