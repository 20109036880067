.card-wrap-container .wrapping-row-item {
  .card-details {
    padding: 2em 2em 0 2em;

    .member-photo-container {
      img {
        width: 7em;
        height: 7em;
      }
    }
  }
}
