@import "../../styles/vars";
.dashboard-container {
  display: flex;

  .dashboard-paper {
    margin: 0 2.5em 2.5em 0;
    flex: 1
  }

  .dashboard-paper-no-right {
    margin: 0 0em 2.5em 0;
    flex: 1
  }

  .feature {
    width: calc((100% / 3) * 2);
  }
}
.new-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: $light-gray solid 2px;
  padding: 0 0 0.75em 0;
  margin-bottom: 2.5em;

  .active {
    color: $primary;
    padding: 0 0 0.25em 0;
    border-bottom: $primary solid 2px;
  }
  .inactive {
    color: $primary;
  }
  h3 {
    cursor: pointer;
  }
}

.upcoming-card {
  box-shadow: $box-shadow;
  width: 32em;
}
.tasks-card {
  box-shadow: $box-shadow;
  width: 48em;
}
