@import "../../styles/vars";
.button {
  .btn {
    cursor: pointer;
    &.button-blue {
      width: 100%;
      background-color: $primary;
      color: white;
      &:hover {
        border-color: $light-gray;
      }
    }
    &.button-gray {
      width: 100%;
      background-color: $light-gray;
      color: $primary;
      &:hover {
        border-color: $light-gray;
      }
    }
    &.button-white {
      width: 100%;
      background-color: white;
      color: $primary;
      border-color: $primary;
      &:hover {
        background: $primary;
        color: white;
        border-color: $med-gray;
      }
    }
    &.button-primary {
      width: 100%;
      background: $primary;
      color: white;
      &:hover {
        color: $primary;
        background: white;
        border-color: $primary;
      }
    }
  }
}
