@import "../../../styles/vars";

.measurements-form-container {
  textarea {
    width: 100%;
    resize: none;
    &:focus {
      outline: none;
    }
  }
  .button {
    margin-bottom: 1em;
    flex: 1;
    flex-grow: 1;
    width: 50%;
  }
  .member-input {
    margin: 0 4em 0 0;
  }
}
