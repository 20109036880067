@import "../../styles/vars";

.poll-item-container {
  color: inherit;
  position: relative;
  .status {
    font-size: 0.6em;
    text-align: center;
    background-color: $dark-gray;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 0;
    width: 100%;
  }
  .title-area {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    font-weight: bold;
    li.title-area {
      display: flex;
      flex-direction: column;
    }
    .title {
      width: 90%;
      flex: 1;
      font-size: 1.1em;
      color: $title-text-dark-gray;
    }
    .date {
      width: 10%;
      flex: 2;
      text-align: right;
      font-weight: normal;
      font-size: 0.6em;
    }
  }
  .details-area {
    color: $med-gray;
    font-size: 0.9em;
    .question {
      margin-bottom: 10px;
      line-height: 1.2em;
    }
    .poll-history-table {
      margin-bottom: 10px;
      width: 100%;
    }
    .poll-history-table td {
      border-bottom: solid 1px $light-gray;
      font-size: 0.7em;
      padding: 10px 0;
      padding-left: 5px;
    }
    .poll-history-table tr td:first-child {
      width: 10%;
      text-align: center;
      padding-left: 0;
      border-right: solid 1px $light-gray;
    }
    .poll-history-table tr td:last-child {
      width: 10%;
      border-left: solid 1px $light-gray;
    }
    .vote-total {
      font-weight: bold;
    }
  }
}

.form-card {
  display: flex;
  .form-control {
    flex: 1;
    padding: 0 1em;
    margin-bottom: 1em;
  }
}

.option-row {
  position: relative;
  .option-row-btn {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
