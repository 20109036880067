@import "../../../styles/vars";

.downshift-input {
  position: relative;
  .input-container {
    margin: 2em 0 2em 0;
    position: relative;
    width: 32em;
    .create-btn {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .input-root {
    width: 90%;
  }
  .input-root div:first-of-type {
    flex-wrap: wrap;
  }
  .downshift-chip {
    margin: 0.5em 0.2em;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    margin-right: 3em;
  }
  .downshift-list {
    position: absolute;
    width: 99%;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.add-tag-modal {
  display: "flex";
  box-shadow: $box-shadow;
  outline: none;
  justify-content: "center";
  padding: 2em;
}
