@import "../../styles/vars";

.button-card-container {
  background-color: white;
  box-shadow: $box-shadow;
  margin: 0 0 3em 0;
  width: 23em;
  flex: 1 1 23em;
  flex-grow: 0;
  margin-right: 2em;
  font-family: "Source Sans Pro";
  display: flex;
  flex-direction: column;

  .event-spacer {
    margin-left: 2em;
  }

  .button-card-content {
    display: flex;
    flex-direction: row;
    padding: 2em 0 0 0.5em;
    border-bottom: solid 1px $light-gray;
    flex: 1;
    align-items: flex-start;
    .card-img,
    img {
      width: 4.5em;
      height: 4.5em;
      margin: 0 2em;
    }
    .button-card-details {
      h3 {
        font-weight: bold;
        color: $title-text-dark-gray;
        padding-bottom: 0.3em;
        font-size: 1.25em;
      }
      h4 {
        color: $sub-text-gray;
        padding-bottom: 0.3em;
        font-size: 1em;
        margin: 0.5em 0;
      }
    }
  }
  .button-card-footer {
    display: flex;
    margin: 1em 0;
    flex-direction: row;
    justify-content: space-evenly;
    .btn {
      padding: 0.3em 1em;
      border: 1px solid $primary;
      border-radius: 0.4em;
      &:hover {
        text-decoration: none;
        background: $primary;
        color: white;
      }
    }
  }
}
