@import "../../styles/vars";
.header {
  background-color: $very-light-gray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  height: 5em;
  .logo {
    @media (min-width: 0) and (max-width: $breakpoint-laptop) {
      flex: 0 0;
    }
    .header-logo {
      display: inline-block;
      padding: 0 1em;
      cursor: pointer;
      a {
        background-color: $very-light-gray;
      }
      .header-img {
        width: 3em;
      }
    }
  }
  .header-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 0) and (max-width: $breakpoint-laptop) {
      margin-right: 1em;
    }
    li {
      border-bottom: 4px solid transparent;
      margin-left: 1.5em;
      a {
        color: $dark-gray;
        text-decoration: none;
        font-size: 1.75em;
        line-height: 1.75em;
      }
    }
    .isCurrent {
      border-bottom: 4px solid $med-gray;
    }
  }
  .header-endblock {
    display: flex;
    align-items: center;
    img {
      padding: 0 1em 0 0;
      height: 1.5em;
    }
    .header-search {
      border: 1px black solid;
      border-radius: 3px;
      margin: 0 2em 0 1em;
      width: 15em;
      line-height: 1em;
      @media (min-width: 0) and (max-width: $breakpoint-laptop) {
        margin-right: 1em;
      }
    }
    .bp3-popover-wrapper {
      display: flex;
      height: 100%;
      align-items: center;
      .bp3-popover-target {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}
.bp3-overlay {
  display: flex;
  justify-content: center;
  .bp3-overlay-content {
    margin: 15em 0 0 0;
    h3 {
      margin: 0 0 2em 0;
    }
  }
}
