.master-content {
  .asset-details-card {
    h1 {
      margin: 2em 0 1em 0;
      color: $med-gray;
      font-weight: 600;
    }
    .asset-form {
      form {
        .featured-image {
          height: 175px;
          width: 175px;
          margin-right: 2em;
          .image-selector {
            background-color: red;
            width: 2em;
          }
          position: relative;
        }
        .add-photo {
          position: absolute;
          bottom: 0.3em;
          right: 0.3em;
        }
        .asset-input {
          margin: 2em 4em 2em 0;
          width: 32em;
        }
      }
    }
  }
}
