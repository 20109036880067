@import "../../../styles/vars";

.create-event-container {
  display: flex;
  flex-direction: column;

  .input-row {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }
  }

  .button {
    width: 110px;

    .btn {
      font-size: 1.25em;
      padding: 0.4em 1em;
    }
  }

  .input-field {
    display: flex;
    flex: 1;
    margin-right: 2em;
    margin-left: 2em;
    margin-bottom: 1em;
  }

  .cancel-button {
    margin-right: auto;
  }
}
