@import "./vars";
.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  .main {
    margin-top: 0em;
    width: 100%;
    flex: 1;
    .master-container {
      position: relative;
      scroll-behavior: smooth;
      display: flex;
      margin-bottom: 3em;
      .master-content {
        flex-grow: 1;
        padding: 2.5em;
      }
    }
  }
}

.danger-text {
  color: $error-red !important;
}

.row {
  display: flex;
  flex-direction: row;
}
.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.column {
  display: flex;
  flex-direction: column;
}
.flex {
  display: flex;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 2em;
}
.mt-3 {
  margin-top: 3em;
}
.mt-4 {
  margin-top: 4em;
}
.mt-5 {
  margin-top: 5em;
}
.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mb-3 {
  margin-bottom: 3em;
}
.mb-4 {
  margin-bottom: 4em;
}
.mb-5 {
  margin-bottom: 5em;
}
