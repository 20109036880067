@import "../../../styles/vars";

.event-photos-card {
  position: relative;
  h1 {
    margin: 2em 0 1em 1.5em;
    color: $med-gray;
    font-weight: 600;
  }

  .section {
    margin-bottom: 1em;
    border-bottom: 1px solid $very-light-gray;
    padding-bottom: 0.5em;
  }

  .plus-button {
    position: absolute;
    top: -1em;
    right: -0.5em;
  }

  .photo-empty-text {
    text-align: center;
    margin: 5em;
    color: $med-gray;
  }

  .event-image-gallery {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .event-photo-container {
    width: 20em;
    height: 20em;
    margin: 0.5em;
    border: 1px solid $light-gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .event-photo-delete-button {
    position: absolute;
    top: 0.25em;
    right: 0.25em;
  }

  .photo-delete-icon {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    padding: 2px;
    color: $light-gray;
  }
}

.photo-drop-container {
  border: 2px dashed $primary;
  border-radius: 5px;
  background: white;
  padding: 4em;
  margin: 2em;
}

.preview-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.event-preview-image {
  width: 10em;
  height: 10em;
  margin: 0.25em;
  object-fit: scale-down;
  border: 1px solid $light-gray;
}

.event-photo-upload-feedback {
  position: absolute;
  top: 0.75em;
  right: 0.75em;
}

.event-dialogue-text {
  padding-top: 1em;
  padding-bottom: 1em;
}
