@import "../../styles/vars";

.conversation-container {
  height: 50vh;
  overflow-y: scroll;
}

.message-item {
  display: flex;
  flex-direction: row;
  width: 100;
  margin: 20px 0;
  text-decoration: none !important;
  color: inherit;
  .profile-pic {
    width: 25%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .details {
    width: 75%;
    display: flex;
    flex-direction: column;
    flex: 1;
    .name {
      font-weight: bold;
      font-size: 1.2em;
      margin-bottom: 10px;
      padding-top: 20px;
    }
    .message {
      color: $med-gray;
      font-size: 1em;
    }
  }
}
