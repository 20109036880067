$primary: #0033a1;
$link-blue: #3e4ad8;
$error-red: #e52626;
$very-light-gray: #f8f8f8;
$light-gray: #d9d9d9;
$sub-header-white: #F2F2F2;
$med-gray: #707070;
$dark-gray: #3d3d3d;
$title-text-dark-gray: #3D3D3D;
$sub-text-gray:#3D3D3D75;
$box-shadow: 1px 2px 2px 1px $light-gray;
$blue-gradient: linear-gradient(180deg, #1642a2 10%, #1A43A2, #081391);
$gray-gradient: linear-gradient(175deg, #8a8c96 10%, #484d55);
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1024px;
$breakpoint-desktop: 1200px;
$helvetica-neue: 'Helvetica Neue LT Std 45 Light';