@import "../../styles/vars";

.poll-history-container {
  .poll-props-container {
    height: 750px;
    overflow-y: scroll;
  }
}
.form-card-header {
  margin-bottom: 20px;
}
.form-row {
  margin-bottom: 20px;
}
.form-single-row {
  margin-bottom: 0px;
}
.form-submit-row {
  text-align: right;
  padding-top: 40px;
  button {
    margin-left: 10px;
  }
}
.form-row label {
  color: $dark-gray;
  display: block;
  margin-bottom: 10px;
}
.form-row input[type="text"] {
  width: 100%;
  box-shadow: 0 4px 2px -2px $light-gray;
  border-radius: 0%;
  display: block;
}
