@import "../../../styles/vars";

.travel-card {
  h1 {
    margin: 2em 0 1em 1.5em;
    color: $med-gray;
    font-weight: 600;
  }

  .travel-header {
    font-size: 16px;
    font-family: $helvetica-neue;
    font-weight: 600;
    color: $med-gray;
  }

  .section {
    margin-bottom: 1em;
    border-bottom: 1px solid $very-light-gray;
    padding-bottom: 0.5em;
  }
}
