@import "../../styles/vars.scss";

.document-drop-container {
  border: 2px dashed $primary;
  border-radius: 5px;
  background: white;
  padding: 4em;
  margin: 2em;
}

.preview-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;

  ul {
    list-style: decimal;

    li {
      margin-bottom: 0.5em;
    }
  }
}

.event-preview-image {
  width: 10em;
  height: 10em;
  margin: 0.25em;
  object-fit: scale-down;
  border: 1px solid $light-gray;
}

.document-upload-feedback {
  position: absolute;
  top: 0.75em;
  right: 0.75em;
}

.document-dialog-text {
  padding-top: 1em;
  padding-bottom: 1em;
}
