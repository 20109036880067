@import "../../styles/vars";

.select-wrapper {
  position: relative;
  background: white;
  font-family: "Helvetica Neue";
  border: none;
  border-bottom: solid 1px $med-gray;
  border-radius: 0;
  padding: 1.6em 0 0 0;
  margin-bottom: 10px;
  > select {
    height: 100%;
    color: $dark-gray;
    &:focus {
      outline: none;
    }
    > option {
      color: black;
    }
  }
}

.select-wrapper > select {
  width: 100%;
  background: white;
  border: 0;
  z-index: 1;
}

.select-wrapper > select::-ms-expand {
  display: none;
}

.select-arrow {
  position: absolute;
  display: inline;
  z-index: 0;
  border: 8px solid transparent;
  border-bottom: 0;
  right: 0;
}
