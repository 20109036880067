@import "../../../styles/vars";

.event-details-card {
  .section {
    margin-bottom: 1em;
    border-bottom: 1px solid $very-light-gray;
    padding-bottom: 0.5em;
  }

  .event-input {
    flex: 1;
    margin-bottom: 1.25em;
  }

  .event-input-small {
    flex: 1;
    max-width: 300px;
    margin-bottom: 1.25em;
  }

  .event-input-extra-small {
    flex: 1;
    max-width: 100px;
    margin-bottom: 1.25em;
  }

  .event-form-margin {
    margin-right: 3em;
  }

  .event-form-margin-bottom {
    margin-bottom: 1.25em;
  }

  label {
    color: $med-gray;
  }

  textarea {
    font-family: $helvetica-neue, "Source Sans Pro", sans-serif;
    padding: 0.5em;
  }

  p {
    font-family: $helvetica-neue, "Source Sans Pro", sans-serif;
    color: $med-gray;
    font-size: 0.8rem;
    align-self: flex-end;
  }

  .event-label {
    color: $med-gray;
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5em;
  }

  .event-primary-image {
    height: 175px;
    width: 175px;
    margin-right: 2em;
    position: relative;

    img {
      height: 100%;
      width: 100%;
    }

    .event-camera-icon {
      position: absolute;
      bottom: 0.25em;
      right: 0.25em;
      height: 2em;
      width: 2em;
      background-color: rgba(0, 0, 0, 0.25);
      border-radius: 1em;
      border: 1px solid #fff;
      padding: 0.4em;
      cursor: pointer;
    }
  }

  .event-btn {
    width: 12em;
    margin-bottom: 0.33em;
    font-size: 1.25em;
    padding: 0.4em 1em;
  }

  .event-published-icon {
    color: green !important;
  }

  #venue-location {
    padding-top: 6em;
    margin-top: -6em;
  }
}
